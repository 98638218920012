<template>
  <div v-if="props.error">
    <OHeaderStatic />
    <OHeroStatic v-if="props.error.statusCode === 404">
      <template #image>
        <img
          :src="Error400"
          alt="The page you are trying to access does not exist or has been deleted"
          width="515px"
        />
      </template>

      <template #title>
        {{ props.error.statusMessage }}
      </template>

      <template #subtitle>
        {{ props.error.message }}
      </template>

      <template #description>
        <ul>
          <li>Check that the entered address is correct</li>
          <li>Go to the main page</li>
        </ul>
      </template>

      <template #button>
        <Button tag="a" href="/" modifier="primary">To the main page</Button>
      </template>
    </OHeroStatic>

    <template v-if="String(props.error.statusCode).startsWith('5')">
      <OHeroStatic>
        <template #image>
          <img
            :src="Error500"
            alt="Something went wrong.… We are already working on the problem"
            width="515px"
          />
        </template>

        <template #title>
          {{ props.error.statusMessage }}
        </template>

        <template #subtitle>Something went wrong.… We are already working on the problem</template>

        <template #description>
          <ul>
            <li>Try refreshing the page</li>
            <li>Go to the main page</li>
          </ul>
        </template>

        <template #button>
          <Button modifier="primary" @click="refresh">Refresh</Button>
        </template>
      </OHeroStatic>

      <pre v-if="props.error.message.length > 1">{{ props.error.message }}</pre>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app';

// Components
import OHeaderStatic from '~/components/O/Header/Static/OHeaderStatic.vue';
import OHeroStatic from '~/components/O/Hero/Static/OHeroStatic.vue';
import Button from '@ice-products-ui/icecorp-vue-library/Button';

// Static
import Error400 from 'public/images/errors/error400.jpg';
import Error500 from 'public/images/errors/error500.jpg';

const props = defineProps({
  error: Object as () => NuxtError,
});

useHead({
  title: props.error?.statusMessage,
  meta: [
    {
      name: 'description',
      content: props.error?.message,
    },
  ],
});

const refresh = () => window.location.reload();
</script>
