import * as Sentry from '@sentry/nuxt';

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  async setup(nuxtApp) {
    const vueApp = nuxtApp.vueApp;

    const config = useRuntimeConfig();

    Sentry.init({
      app: vueApp,
      dsn: config.public.NUXT_SENTRY_DSN,
      tracesSampleRate: parseFloat(config.public.NUXT_SENTRY_TRACES_SAMPLE_RATE ?? '1.00'),
      integrations: [],
    });
  },
});
