<template>
  <header class="o-header-default">
    <div class="container">
      <div class="o-header-default__container">
        <div class="o-header-default__left-column">
          <a href="/" class="o-header-default__logo-link">
            <svg
              width="187"
              height="31"
              viewBox="0 0 187 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="o-header-default__logo-image"
            >
              <path
                d="M62 15.6824C62 23.9776 48.1208 30.7022 31 30.7022C13.8792 30.7022 0 23.9776 0 15.6824C0 7.38724 13.8792 0.662659 31 0.662659C48.1208 0.662659 62 7.38724 62 15.6824Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M52.6732 25.7765C58.2327 23.0829 61.4259 19.4755 61.4259 15.6824C61.4259 11.8894 58.2327 8.28198 52.6732 5.58834C47.1562 2.91532 39.4943 1.24595 31 1.24595C22.5057 1.24595 14.8438 2.91532 9.32678 5.58834C3.76726 8.28198 0.574074 11.8894 0.574074 15.6824C0.574074 19.4755 3.76726 23.0829 9.32678 25.7765C14.8438 28.4495 22.5057 30.1189 31 30.1189C39.4943 30.1189 47.1562 28.4495 52.6732 25.7765ZM31 30.7022C48.1208 30.7022 62 23.9776 62 15.6824C62 7.38724 48.1208 0.662659 31 0.662659C13.8792 0.662659 0 7.38724 0 15.6824C0 23.9776 13.8792 30.7022 31 30.7022Z"
                fill="#44456B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M51.5891 24.6573C56.8829 22.2405 59.876 19.0222 59.876 15.6824C59.876 12.3427 56.8829 9.12434 51.5891 6.70752C46.3494 4.31544 39.0706 2.82084 31 2.82084C22.9295 2.82084 15.6507 4.31544 10.411 6.70752C5.11717 9.12434 2.12412 12.3427 2.12412 15.6824C2.12412 19.0222 5.11717 22.2405 10.411 24.6573C15.6507 27.0494 22.9295 28.544 31 28.544C39.0706 28.544 46.3494 27.0494 51.5891 24.6573ZM31 29.1273C47.2648 29.1273 60.4501 23.1078 60.4501 15.6824C60.4501 8.25703 47.2648 2.23755 31 2.23755C14.7353 2.23755 1.55005 8.25703 1.55005 15.6824C1.55005 23.1078 14.7353 29.1273 31 29.1273Z"
                fill="#44456B"
              />
              <path
                d="M60.2465 17.2714C58.5246 23.9479 46.0872 29.1273 31 29.1273C14.7353 29.1273 1.55005 23.1078 1.55005 15.6824C1.55005 15.676 1.55006 15.6695 1.55008 15.663C4.87354 16.8307 10.5456 18.1644 18.9445 18.0464C26.1681 17.9449 28.9017 17.2176 31.5879 16.503C34.3773 15.7609 37.1156 15.0324 44.7778 15.0324C51.2773 15.0324 56.4582 16.0807 60.2465 17.2714Z"
                fill="#44456B"
              />
              <path
                d="M12.3356 8.34133L8.5979 19.7346H10.934L10.1553 20.684H16.2291L15.1389 22.1082H26.8194L26.3521 21.6335H29.7784L29.3112 21.1587H31.0243L28.6882 14.9874L25.8849 13.8797L21.5242 2.48646L18.2537 0.429342L15.4504 7.39189L12.3356 8.34133Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.1165 0L21.757 2.28983L26.1067 13.6544L28.9106 14.7623L31.4423 21.4504H30.0042L30.4714 21.9251H27.0452L27.5124 22.3998H14.5521L15.6423 20.9757H9.5425L10.3212 20.0262H8.19971L12.1105 8.10548L15.2367 7.15256L18.1165 0ZM26.1264 21.8165L25.6592 21.3418H29.0855L28.6183 20.8671H30.6064L28.4659 15.2125L25.6632 14.105L21.2916 2.68309L18.391 0.858685L15.6642 7.63121L12.5609 8.57717L8.99619 19.4429H11.5469L10.7682 20.3924H16.816L15.7259 21.8165H26.1264Z"
                fill="#44456B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.1322 12.961L21.2881 12.8878L23.6242 17.1603L23.4737 17.2452L21.7004 14.0022L23.1624 17.9632L23.0011 18.0247L21.1322 12.961ZM14.8539 14.8095L15.3545 16.1658H16.7471L17.5504 17.635L17.3999 17.72L16.6458 16.3408H15.2351L14.8013 15.1653L10.6908 20.5792L10.5544 20.4723L14.8539 14.8095ZM26.8823 18.7253L29.2184 21.2571L29.0927 21.3768L26.7566 18.845L26.8823 18.7253ZM18.0664 18.8405L16.6648 20.5812L16.5315 20.4704L17.9331 18.7297L18.0664 18.8405ZM24.235 19.5168L26.2596 21.7322L26.1334 21.8512L24.1088 19.6359L24.235 19.5168Z"
                fill="#44456B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20.2332 4.04575L20.4041 4.0301L21.027 7.51137L20.8576 7.54268L20.4054 5.01608V7.36879H20.2332V4.04575ZM14.7876 9.55411L15.6414 7.18051L15.8031 7.24058L14.9494 9.61417L14.7876 9.55411ZM18.0529 11.0727L17.2863 13.4096L17.1229 13.3542L18.0573 10.5059L18.2251 10.5336V13.0654H18.0529V11.0727Z"
                fill="#44456B"
              />
              <path
                d="M39.2668 12.0369C37.6284 12.0369 36.2241 10.6538 36.2241 8.94541C36.2241 7.23698 37.5504 5.85396 39.2668 5.85396C40.905 5.85396 42.3093 7.23698 42.3093 8.94541C42.3093 10.6538 40.9831 12.0369 39.2668 12.0369Z"
                fill="#F14343"
              />
              <path
                d="M172.869 12.9538C172.869 10.1906 175.204 8.60038 178.72 8.60038H186.341V11.2072H184.079C184.079 11.2072 184.596 11.7025 184.596 13.0842C184.596 15.8474 182.235 17.3073 178.72 17.3073C176.925 17.3073 176.065 17.5419 176.065 18.2457C176.065 18.9235 176.999 19.1842 178.72 19.1842C182.727 19.1842 185.702 20.2791 185.702 23.303C185.702 26.3531 182.727 27.3697 178.72 27.3697C174.737 27.3697 171.762 26.3531 171.762 23.303C171.762 20.7223 174.172 19.9141 174.172 19.9141V19.8099C174.172 19.8099 173.237 19.3146 173.237 18.2457C173.237 16.9163 174.688 16.3949 174.688 16.3949V16.3167C174.688 16.3167 172.893 15.5085 172.869 12.9538ZM178.72 21.9214C176.679 21.9214 175.401 22.2864 175.401 23.303C175.401 24.3197 176.679 24.6847 178.72 24.6847C180.76 24.6847 182.039 24.3197 182.039 23.303C182.039 22.2864 180.76 21.9214 178.72 21.9214ZM178.72 11.2072C177.195 11.2072 176.507 11.9111 176.507 12.9799C176.507 14.0487 177.195 14.7526 178.72 14.7526C180.244 14.7526 180.932 14.0487 180.932 12.9799C180.932 11.9111 180.244 11.2072 178.72 11.2072Z"
                fill="#44456B"
              />
              <path
                d="M169.807 8.60038C170.397 8.60038 171.037 8.73072 171.037 8.73072V12.8756C171.037 12.8756 170.25 12.4846 168.898 12.4846C166.513 12.4846 165.333 14.1008 165.333 16.1342V21.8953H161.793V8.86106H165.333V11.2594H165.53C165.53 11.2594 166.488 8.60038 169.807 8.60038Z"
                fill="#44456B"
              />
              <path
                d="M159.905 16.3688H149.703C149.703 17.8026 150.784 19.2363 153.046 19.2363C155.136 19.2363 155.8 17.9329 155.8 17.9329H159.684C159.684 17.9329 158.947 22.156 153.046 22.156C148.94 22.156 145.99 19.7316 145.99 15.3782C145.99 11.0247 148.94 8.60038 152.997 8.60038C157.029 8.60038 159.979 11.0247 159.979 15.3782C159.979 15.8474 159.905 16.3688 159.905 16.3688ZM152.997 11.52C150.784 11.52 149.752 13.032 149.752 14.0748H156.218C156.218 13.032 155.259 11.52 152.997 11.52Z"
                fill="#44456B"
              />
              <path
                d="M144.177 15.3698C144.177 19.6971 141.472 22.1476 138.227 22.1476C135.031 22.1476 133.876 19.7493 133.876 19.7493H133.704V21.8869H130.163V3.63895H133.704V10.4428H133.851C133.851 10.4428 135.13 8.59197 138.227 8.59197C141.472 8.59197 144.177 11.0424 144.177 15.3698ZM140.538 15.3698C140.538 13.4146 139.26 11.9287 137.121 11.9287C135.056 11.9287 133.704 13.3364 133.704 15.3698C133.704 17.3249 134.982 18.8108 137.121 18.8108C139.26 18.8108 140.538 17.3249 140.538 15.3698Z"
                fill="#44456B"
              />
              <path
                d="M128.276 16.3688H118.073C118.073 17.8026 119.155 19.2363 121.417 19.2363C123.506 19.2363 124.17 17.9329 124.17 17.9329H128.055C128.055 17.9329 127.317 22.156 121.417 22.156C117.311 22.156 114.361 19.7316 114.361 15.3782C114.361 11.0247 117.311 8.60038 121.368 8.60038C125.399 8.60038 128.35 11.0247 128.35 15.3782C128.35 15.8474 128.276 16.3688 128.276 16.3688ZM121.368 11.52C119.155 11.52 118.122 13.032 118.122 14.0748H124.588C124.588 13.032 123.629 11.52 121.368 11.52Z"
                fill="#44456B"
              />
              <path
                d="M98.6814 15.3782C98.6814 11.0508 101.632 8.60038 105.688 8.60038C110.556 8.60038 112.547 12.1196 112.547 14.2051H108.909C108.909 14.2051 108.442 11.8329 105.688 11.8329C103.525 11.8329 102.32 13.3709 102.32 15.3782C102.32 17.3855 103.525 18.9235 105.688 18.9235C108.442 18.9235 108.909 16.5513 108.909 16.5513H112.547C112.547 18.6368 110.556 22.156 105.688 22.156C101.632 22.156 98.6814 19.7056 98.6814 15.3782Z"
                fill="#44456B"
              />
              <path d="M96.6711 8.84412V21.8784H93.1309V8.84412H96.6711Z" fill="#44456B" />
              <path
                d="M94.9377 7.99976C96.1429 7.99976 97.12 6.9633 97.12 5.68475C97.12 4.40621 96.1429 3.36974 94.9377 3.36974C93.7324 3.36974 92.7554 4.40621 92.7554 5.68475C92.7554 6.9633 93.7324 7.99976 94.9377 7.99976Z"
                fill="#44456B"
              />
              <path
                d="M88.2739 17.6434C89.4791 17.6434 90.4562 16.6069 90.4562 15.3284C90.4562 14.0498 89.4791 13.0134 88.2739 13.0134C87.0686 13.0134 86.0916 14.0498 86.0916 15.3284C86.0916 16.6069 87.0686 17.6434 88.2739 17.6434Z"
                fill="#44456B"
              />
              <path
                d="M70 18.2197C70 15.3782 72.5568 14.2051 75.507 14.2051C78.2852 14.2051 79.4898 15.3 79.4898 15.3V13.397C79.4898 12.4325 78.9244 11.4158 76.8838 11.4158C75.8267 11.4158 74.2532 11.8068 74.2532 13.0581H70.6146C70.6392 9.98201 73.1961 8.60038 76.8838 8.60038C80.6453 8.60038 83.0301 10.1124 83.0301 13.397V17.4637C83.0301 18.5586 83.2759 18.8714 83.8414 18.8714C84.1856 18.8714 84.3577 18.7671 84.3577 18.7671V21.8953C84.3577 21.8953 83.9151 22.156 82.8334 22.156C80.4241 22.156 79.8586 20.3312 79.8586 20.3312H79.6865C79.6865 20.4094 78.7277 22.156 75.0891 22.156C72.3602 22.156 70 20.9308 70 18.2197ZM76.5396 19.3145C78.7031 19.3145 79.4898 18.3239 79.4898 18.3239V17.5419C79.4898 17.5419 78.7769 16.7598 76.5396 16.7598C74.9662 16.7598 73.6386 17.0205 73.6386 18.0372C73.6386 19.0799 74.9662 19.3145 76.5396 19.3145Z"
                fill="#F14343"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
// Composables
import useScroll from '~/composables/dom/useScroll';

defineOptions({
  name: 'OHeaderStatic',
});

useScroll(0, 'header-scrolled', 1000);
</script>
